import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { JobSummary } from "../components/job-components"

const Careers = () => {
  return (
    <Layout>
      <SEO
        title="Work at Celantur"
        description="Join our team and start your career at Celantur."
      />

      <section
        className="container-fluid pt-8 pt-md-12 pb-8 pb-md-12 overlay overlay-black overlay-80 bg-cover text-center"
        style={{
          background: "url(/images/team-unsplash.jpg) no-repeat",
          backgroundPositionY: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <h1 className="display-2 font-weight-bold text-white">
            Work at Celantur
          </h1>
          <p className="lead font-weight-bold mb-6 mb-md-8 text-warning">
            Join our team on its journey to conquer the emerging privacy tech
            market
          </p>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="table-responsive table-striped mb-7 mb-md-9">
                <table className="table table-align-middle">
                  <thead>
                    <tr>
                      <th>
                        <span className="h6 text-uppercase font-weight-bold">
                          Role
                        </span>
                      </th>
                      <th>
                        <span className="h6 text-uppercase font-weight-bold">
                          Starting Date
                        </span>
                      </th>
                      <th>
                        <span className="h6 text-uppercase font-weight-bold">
                          Working Hours &amp;<br />Location
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <JobSummary
                      title="Senior Software Developer - AWS, Python, JavaScript"
                      location="Hybrid Office in Linz; Remote in Austria"
                      workingTime="Full-time/Part-time"
                      link="/job-software-developer/"
                      startingDate="ASAP"
                      highlight={true}
                    >
                      Elevate our products and services to the next level, with Python/JS/Docker/AWS.
                    </JobSummary>
                    <JobSummary
                      title="Machine Learning and Computer Vision Engineer"
                      location="Hybrid Office in Linz; Remote in Austria"
                      workingTime="Full-time/Part-time"
                      link="/job-machine-learning-engineer/"
                    >
                      Machine Learning and Computer Vision in
                      Python and C++ (CUDA).
                    </JobSummary>
                    <JobSummary
                      title="Performance Engineer for a highly scalable ML product"
                      location="Hybrid Office in Linz; Remote in Austria"
                      workingTime="Full-time/Part-time"
                      link="/job-performance-engineer/"
                    >
                      Build scalable and performance optimized products with C++/CUDA/Python/AWS.
                    </JobSummary>
                    <JobSummary
                      title="Technical Sales Manager, ML Software"
                      location="Hybrid Office in Linz; Remote in Austria"
                      workingTime="Full-time/Part-time"
                      link="/job-technical-sales-manager/"
                    >
                      Acquire new and serve existing Celantur customers.
                    </JobSummary>
                    <JobSummary
                      title="Image Annotator, Internship/Student Job"
                      location="Linz, Austria"
                      workingTime="Part-time (geringfügig)"
                      link="/job-intern-image-annotator/"
                    >
                      Actively improve our ML/AI models and collect experience with MLOps workflows.
                    </JobSummary>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Careers
